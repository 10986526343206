"use client";

import { Card, type CardProps } from "@mui/material";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type { FC, ReactNode } from "react";

import type { Network } from "@/api/app";
import { useAppSelector } from "@/lib/hooks/client";
import { selectUser } from "@/lib/state";
import { isAdmin } from "@/lib/util";

interface NetworkOverviewLinkProps extends Omit<CardProps<"a">, "href" | "component"> {
  network: Network;
  children: ReactNode;
}

export const NetworkOverviewLinkCard: FC<NetworkOverviewLinkProps> = (props) => {
  const { network, children, ...restProps } = props;
  const pathname = usePathname();
  const user = useAppSelector(selectUser);
  const href = user != null && isAdmin(user) ? `${pathname}/network/${network.id}` : `/me/network/${network.id}`;

  return (
    <Card {...restProps} component={Link} href={href}>
      {children}
    </Card>
  );
};
