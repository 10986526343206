"use client";

import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import { PlusIcon } from "@/ui/shared/icons";

import { NetworkPickerDialog } from "../../feature/modals";

export const AddNewNetwork = () => {
  const [open, setOpen] = useState(false);
  const plusIconWrapperSizeInPx = 80;
  const heightInPx = 296.95; // This value is same as height of siblings in NetworkOverviewList.ts

  const handleOnOpen = () => setOpen(true);

  const handleOnClose = () => setOpen(false);

  const handleNetworkSelect = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={6} sm={4} md={3}>
        <Card
          component={"button"}
          onClick={handleOnOpen}
          sx={{
            py: 3,
            px: 2,
            minHeight: heightInPx,
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
            border: "none",
            cursor: "pointer",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box
              sx={{
                width: plusIconWrapperSizeInPx,
                height: plusIconWrapperSizeInPx,
                bgcolor: "var(--surface-base)",
                borderRadius: Number.MAX_SAFE_INTEGER,
                display: "grid",
                placeItems: "center",
              }}
            >
              <PlusIcon />
            </Box>
            <Typography variant="h4">Add New Network</Typography>
          </Box>
        </Card>
      </Grid>

      <NetworkPickerDialog open={open} onClose={handleOnClose} keepMounted onNetworkSelect={handleNetworkSelect} />
    </>
  );
};
