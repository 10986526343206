"use client";

import { Button } from "@mui/material";
import { useState } from "react";

import { type ContactSalesQuery, postQuery } from "@/api/app";

import { ContactSalesFormModal } from "../../modals/ContactSalesFormModal";

export const ContactSalesButton = () => {
  const [open, setOpen] = useState(false);
  const toggle = (value: boolean) => () => setOpen(() => value);

  const handleOnSubmit = async (data: ContactSalesQuery) => {
    await postQuery(data);
  };

  return (
    <>
      <Button size="medium" sx={{ bgcolor: "rgba(255, 137, 126, 1)", color: "rgba(38, 6, 4, 0.87)" }} onClick={toggle(true)}>
        Contact sales
      </Button>

      <ContactSalesFormModal open={open} onClose={toggle(false)} handler={handleOnSubmit} onSuccess={toggle(false)} />
    </>
  );
};
