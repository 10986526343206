"use client";

import { Box, type BoxProps, Button, Grid, Skeleton, Typography } from "@mui/material";
import Link from "next/link";
import React, { type FC, forwardRef, useEffect, useState } from "react";

import { getAllNetworks, type Network } from "@/api/app";
import { generateRandomNumber } from "@/lib/util";
import { TextContent } from "@/ui/shared";

import { NetworkSelect } from "./NetworkSelect";

interface NetworkPickerBaseProps {
  onNetworkSelect?: (network: Network) => void;

  initialLoading?: boolean;
}

const sx: BoxProps["sx"] = {
  backgroundColor: "white",
  borderRadius: 3,
  marginX: 2,
  outline: "none",
  boxShadow: 2,
  width: "min(calc(100vw - 2rem), 1007px)",
  bgcolor: "var(--surface-level-2)",
};

/**
 * Renders a dialog for selecting a network.
 *
 * @component
 * @example
 * ```tsx
 * <NetworkPickerDialog
 *   onNetworkSelect={handleNetworkSelect}
 * />
 * ```
 *
 * @param {NetworkPickerBaseProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export const NetworkPickerBase: FC<NetworkPickerBaseProps> = forwardRef((props, ref) => {
  const [networks, setNetworks] = useState<Network[]>([]);
  const [loading, setLoading] = useState(props.initialLoading ?? false);
  const [network, setNetwork] = useState<Network | null>(null);
  const { onNetworkSelect } = props;

  useEffect(() => {
    const initNetworks = async (signal: AbortSignal) => {
      try {
        setLoading(true);
        const networkList = await getAllNetworks({ requestConfig: { signal } });
        const firstNetwork = networkList[0];

        setNetworks(networkList);
        if (firstNetwork != null) {
          setNetwork(firstNetwork);
        }
      } catch (error) {
        setNetworks([]);
      } finally {
        setLoading(false);
      }
    };
    const controller = new AbortController();
    void initNetworks(controller.signal);

    return () => {
      controller.abort("Network picker dialog unmounted");
    };
  }, []);

  const handleOnConfirm = () => {
    if (network == null) {
      return;
    }

    onNetworkSelect?.(network);
  };

  return (
    <Box sx={sx} ref={ref}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ py: { xs: 3, md: 6 }, px: 3, overflow: "hidden", bgcolor: "var(--surface-level-5)", borderRadius: 2, boxShadow: 3 }}
        >
          <NetworkSelect loading={loading} networks={networks} selected={network} onNetworkSelect={setNetwork} />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ py: { xs: 3, md: 6 }, px: 3, display: "grid", rowGap: 2, gridTemplateRows: "auto 1fr auto" }}>
          <Box sx={{ width: "100%" }}>
            <Typography variant="overline" fontSize={9}>
              Network Overview
            </Typography>
            <Typography variant="h2" color="var(--text-high-emphasis)">
              {network?.name}
            </Typography>
          </Box>
          {loading ? (
            <Box display="grid">
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} variant="text" width={`${generateRandomNumber(10, 100)}%`} />
              ))}
            </Box>
          ) : network != null ? (
            <>
              <Box sx={{ "& * + *": { mt: 1 } }}>
                <TextContent text={network.description} TypographyProps={{ variant: "bodyrg", color: "var(--text-medium-emphasis)" }} />
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button size="small" onClick={handleOnConfirm} LinkComponent={Link} href={`/network/${network?.id}`}>
                  Start hosting
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="bodyrg">No network selected</Typography>
              <Typography mt={3}>Following can be reasons for this:</Typography>
              <Box component="ul">
                <Typography variant="bodysm" component="li">
                  No network selected
                </Typography>
                <Typography variant="bodysm" component="li">
                  Network is not available
                </Typography>
                <Typography variant="bodysm" component="li">
                  Application does not have any protocols network
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

NetworkPickerBase.displayName = NetworkPickerBase.name;
