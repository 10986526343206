"use client";

import { Modal, type ModalOwnProps } from "@mui/material";
import React, { type FC } from "react";

import type { Network } from "@/api/app";

import { NetworkPickerBase } from "../../client/NetworkPickerBase/NetworkPickerBase";

interface NetworkPickerDialogProps extends Omit<ModalOwnProps, "children"> {
  onNetworkSelect?: (network: Network) => void;
}

/**
 * Renders a dialog for selecting a network.
 *
 * @component
 * @example
 * ```tsx
 * <NetworkPickerDialog
 *   open={true}
 *   onClose={handleClose}
 *   onNetworkSelect={handleNetworkSelect}
 * />
 * ```
 *
 * @param {NetworkPickerDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export const NetworkPickerDialog: FC<NetworkPickerDialogProps> = (props) => {
  const { onNetworkSelect, sx, ...restProps } = props;

  const handleOnConfirm = (network: Network) => {
    if (network == null) {
      return;
    }

    onNetworkSelect?.(network);
  };

  return (
    <Modal
      aria-labelledby="network-picker-modal"
      aria-describedby="select-your-network"
      sx={{ display: "grid", placeItems: "center", ...sx }}
      {...restProps}
    >
      <NetworkPickerBase onNetworkSelect={handleOnConfirm} />
    </Modal>
  );
};
