import { Box, FormControl, InputBase, Skeleton, Typography } from "@mui/material";
import React, { type FC, useState } from "react";

import type { Network } from "@/api/app";

import { NetworkSelectItem } from "./NetworkSelectItem";

interface NetworkSelectProps {
  loading: boolean;
  networks: Network[];
  selected: Network | null;
  onNetworkSelect: (network: Network) => void;
}

/**
 * Filters an array of networks by name based on a query.
 * @param networks - The array of networks to filter.
 * @param query - The query string to match against network names.
 * @returns An array of networks that match the query.
 */
const filterNetworkByName = (networks: Network[], query: string) => {
  if (query === "") {
    return networks;
  }

  return networks.filter((network) => network.name.toLowerCase().includes(query.toLowerCase()));
};

export const NetworkSelect: FC<NetworkSelectProps> = (props) => {
  const { networks, selected, onNetworkSelect, loading } = props;
  const [query, setQuery] = useState("");

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setQuery(text);
  };

  const isSelectedNetwork = (network: Network) => selected?.id === network.id;
  const isInputDisabled = loading === true;
  const filteredNetworks = filterNetworkByName(networks, query);
  return (
    <>
      <FormControl required size={"small"} fullWidth disabled={isInputDisabled}>
        <InputBase placeholder="Find your network" name="name" autoComplete="network name" onChange={onQueryChange} />
      </FormControl>

      <Box
        component="ul"
        sx={{
          height: { md: 300 },
          maxHeight: { xs: "30vh", md: "unset" },
          overflowY: "auto",
          borderRadius: 3,
          mt: { xs: 2, md: 3 },
          "& > * + *": {
            mt: 1,
          },
        }}
      >
        {loading && Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} variant="rounded" height={80} sx={{ borderRadius: 2 }} />)}
        {filteredNetworks.length === 0 ? (
          <Typography variant="bodyrg" textAlign="center" mt={3}>
            No networks found
          </Typography>
        ) : (
          filteredNetworks.map((network) => (
            <NetworkSelectItem key={network.id} network={network} selected={isSelectedNetwork(network)} onSelect={() => onNetworkSelect?.(network)} />
          ))
        )}
      </Box>
    </>
  );
};
