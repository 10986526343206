"use client";

import { Box, Button, Card, CardHeader, FormControl, FormLabel, InputBase, Modal, type ModalProps, Stack, SvgIcon, Typography } from "@mui/material";
import { isAxiosError } from "axios";
import { type FC, type FormEvent, useEffect, useState } from "react";

import { type ContactSalesQuery, getErrorFromAxiosError } from "@/api/app";
import { UnknownError } from "@/lib/util";
import { CloseIcon } from "@/ui/shared/icons";

interface ContactSalesFormModalProps extends Omit<ModalProps, "children"> {
  handler: (data: ContactSalesQuery) => Promise<void> | void;

  onSuccess?: () => void;
}

const MAX_QUERY_CHARACTERS = 200;
const MAX_NAME_CHARACTERS = 50;

const initForm = (): ContactSalesQuery => ({ name: "", email: "", phoneNumber: "", query: "" });

export const ContactSalesFormModal: FC<ContactSalesFormModalProps> = (props) => {
  const [form, setForm] = useState<ContactSalesQuery>(() => initForm());
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const { handler, onSuccess, ...modalProps } = props;
  const { sx, ...rest } = modalProps;

  useEffect(() => {
    if (modalProps.open) {
      setForm(initForm());
      setError(null);
    }
  }, [modalProps.open]);

  const onFormValueChange = (key: keyof ContactSalesQuery, value: string) => {
    if (error) {
      setError(null);
    }

    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmission = async (data: ContactSalesQuery) => {
    try {
      setLoading(true);
      await handler(data);
      onSuccess?.();
    } catch (err) {
      if (isAxiosError(err)) {
        setError(getErrorFromAxiosError(err));
        return;
      }

      setError(new UnknownError({ cause: err }));
    } finally {
      setLoading(false);
    }
  };

  const handleOnFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.name === "") {
      setError(new Error("Name is required"));
      return;
    }

    if (form.name.length > MAX_NAME_CHARACTERS) {
      setError(new Error(`Name should be less than ${MAX_NAME_CHARACTERS} characters`));
      return;
    }

    if (form.email === "") {
      setError(new Error("Email is required"));
      return;
    }

    if (form.phoneNumber === "") {
      setError(new Error("Phone number is required"));
      return;
    }

    if (form.query === "") {
      setError(new Error("Query is required"));
    }

    if (form.query.length >= MAX_QUERY_CHARACTERS) {
      setError(new Error(`Query should be less than ${MAX_QUERY_CHARACTERS} characters`));
      return;
    }

    void handleSubmission(form);
  };

  return (
    <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", ...sx }} {...rest}>
      <Card sx={{ px: 3, py: 4, width: "calc(100dvw - 2rem)", maxWidth: 528, outline: "none" }} component={Stack} rowGap={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CardHeader title="Contact for sales" />
          <Button variant="ghost" size="small" onClick={() => modalProps?.onClose?.({}, "backdropClick")}>
            <SvgIcon component="i">
              <CloseIcon />
            </SvgIcon>
          </Button>
        </Box>

        <Box component="form" onSubmit={handleOnFormSubmit}>
          <Stack spacing={2}>
            <FormControl required fullWidth size="small">
              <FormLabel htmlFor="name">Name</FormLabel>
              <InputBase
                placeholder="Enter your name"
                id="name"
                name="name"
                autoComplete="name"
                value={form.name}
                required
                onChange={(e) => onFormValueChange("name", e.target.value)}
              />
            </FormControl>

            <FormControl required fullWidth size="small">
              <FormLabel htmlFor="email">Email</FormLabel>
              <InputBase
                type="email"
                placeholder="Enter your email"
                id="email"
                value={form.email}
                autoComplete="email"
                name="email"
                required
                onChange={(e) => onFormValueChange("email", e.target.value)}
              />
            </FormControl>

            <FormControl required fullWidth size="small">
              <FormLabel htmlFor="phone-number">Phone Number</FormLabel>
              <InputBase
                type="tel"
                placeholder="Enter your phone number"
                id="phone-number"
                value={form.phoneNumber}
                autoComplete="phone-number"
                name="phone-number"
                required
                onChange={(e) => onFormValueChange("phoneNumber", e.target.value)}
              />
            </FormControl>

            <FormControl required fullWidth size="small">
              <FormLabel htmlFor="query">Query</FormLabel>
              <InputBase
                multiline
                placeholder="Please share your query here"
                id="query"
                value={form.query}
                name="query"
                onChange={(e) => onFormValueChange("query", e.target.value)}
                required
                sx={{ borderRadius: 1 }}
              />
            </FormControl>

            {error != null && (
              <Typography variant="bodysm" color="var(--mean-error)">
                {error.message}
              </Typography>
            )}

            <Box>
              <Button type="submit" size="small" disabled={loading || error != null}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
};
