import { Box, SvgIcon, Typography } from "@mui/material";
import Image from "next/image";
import React, { type FC } from "react";

import type { Network } from "@/api/app";
import { ArrowRightWithoutTail } from "@/ui/shared/icons";

interface NetworkSelectProps {
  onSelect: () => void;

  selected?: boolean;

  network: Network;
}

export const NetworkSelectItem: FC<NetworkSelectProps> = (props) => {
  const { onSelect, selected, network } = props;

  return (
    <Box
      component="li"
      onClick={onSelect}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
        px: 3,
        py: 2,
        cursor: "pointer",
        transition: "background-color 150ms ease-in-out",
        borderRadius: 2,
        bgcolor: selected ? "var(--primary-color)" : undefined,
        color: selected ? "white" : "var(--text-high-emphasis)",
        "& img": {
          padding: 1,
          borderRadius: Number.MAX_SAFE_INTEGER,
          transition: "background-color 150ms ease-in-out",
          bgcolor: selected ? "white" : undefined,
        },
        ".arrow-icon": {
          display: selected ? "block" : "none",
        },
        ":hover": {
          bgcolor: "var(--primary-color)",
          color: "white",

          "& img": {
            backgroundColor: "white",
          },
        },
      }}
    >
      <Image src={network.logoHref} alt={network.name} width={48} height={48} />
      <Typography fontSize={18} fontWeight={500} flex={1}>
        {network.name}
      </Typography>
      <SvgIcon component="div" className="arrow-icon">
        <ArrowRightWithoutTail />
      </SvgIcon>
    </Box>
  );
};
