"use client";

import { Box, Button, type ButtonProps, Modal, Typography } from "@mui/material";
import { type FC, type MouseEventHandler, useState } from "react";

import { config } from "@/app/config";
import { getGoogleOAuthUrl } from "@/lib/util";
import { ApplicationLogo } from "@/ui/shared";
import { GoogleIcon } from "@/ui/shared/icons";

interface LoginButtonProps extends Omit<ButtonProps, "children"> {
  label: string;
}

export const LoginButton: FC<LoginButtonProps> = (props) => {
  const [open, setOpen] = useState(false);
  const { onClick, label, ...rest } = props;
  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    setOpen(true);
    onClick?.(e);
  };

  return (
    <>
      <Button variant="contained" color="primary" size="medium" {...rest} onClick={handleOnOpen}>
        {label}
      </Button>

      <Modal open={open} onClose={handleOnClose}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            width: { xs: "min(80%, 350px)", sm: "min(50%, 400px)" },
            mx: "auto",
            px: { xs: 4, sm: 6 },
            py: 4,
            borderRadius: 5,
            boxShadow: 5,
          }}
        >
          <Box component="div" display="flex" alignItems="center" gap={1}>
            <ApplicationLogo size={35} />
            <Typography sx={{ color: "black" }} fontSize={20} fontWeight={300}>
              {config.appName}
            </Typography>
          </Box>

          <Typography variant="h6" fontWeight={300} fontSize={18} textAlign="center">
            Login/Signup
          </Typography>

          <a href={getGoogleOAuthUrl()}>
            <Box
              component="span"
              border="none"
              boxShadow={3}
              display="flex"
              alignItems="center"
              gap={2}
              p={2}
              borderRadius={3}
              fontFamily={"inherit"}
              bgcolor="white"
              sx={{
                cursor: "pointer",
                ":active": {
                  boxShadow: "var(--elevation-1)",
                },
              }}
            >
              <GoogleIcon />

              <Box>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ fontWeight: 300, fontSize: { xs: "clamp(1rem, 2vw, 1.125rem)" } }}
                  color="var(--text-high-emphasis)"
                >
                  Continue with Google
                </Typography>
              </Box>
            </Box>
          </a>
        </Box>
      </Modal>
    </>
  );
};
